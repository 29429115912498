@keyframes zoom-fade-in {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    50% {
        transform: scale(0.3);
        opacity: 0.3;
    }

    100% {
        transform: scale(0.5);
        opacity: 1;
    }
}


@keyframes moveStraight {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
    
@keyframes moveCurved {
0% {
        transform: translateX(-100%) translateY(100vh);
        /* Start from lower-left corner */
    }
10% {
    transform: translateX(0.2vw) translateY(-0.2vh);
    /* Curve to the middle of the screen */
}
20% {
    transform: translateX(0.5vw) translateY(-0.5vh);
    /* Curve to the middle of the screen */
}

    100% {
        transform: translateX(calc(100vw + 100px)) translateY(100vh);
        /* End at lower-right corner */
    }
}

.image{
    animation: moveCurved 10s linear infinite;;
    background-size: cover;
    position: absolute;
    
    animation-delay: 12s;
    animation-fill-mode: both;
    
        width: 100px;
        height: 100px;
    
}

.image4 {
    animation: moveCurved 10s linear infinite;
    
    background-size: cover;
    position: absolute;
    animation-delay: 20s;
    
    animation-fill-mode: both;


    width: 100px;
    height: 100px;

}

.image5 {
    animation: moveCurved 10s linear infinite;

    background-size: cover;
    position: absolute;
    animation-delay: 26s;
    
    animation-fill-mode: both;


    width: 100px;
    height: 100px;

}

.image6 {
    animation: moveCurved 10s linear infinite;

    background-size: cover;
    position: absolute;
    animation-delay: 36s;

    animation-fill-mode: both;


    width: 100px;
    height: 100px;

}

.image2 {
    animation: zoom-fade-in 10s ease-in-out infinite;
    animation-delay: 4s;
}
.image3 {
    animation: zoom-fade-in 10s ease-in-out infinite;
    animation-delay: 6s;
}


.icon-container {
position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
   
}

.icon-content{

    position: relative;
        width: 100vw;
        
}


