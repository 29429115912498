


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.inactive {
  color: #000000;
  text-decoration: none;
}

.inactive:hover{
  color: #fff;
  text-decoration: none;
}

.active {
  color: white;
  
  text-decoration: none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.card {
  text-align: center;
  padding: 30px 30px;
 color: #fff;
background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-radius: 10px;}
/*   
  background-color:transparent;
  -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border-width: 0px;
text-shadow: -1px 4px 5px rgba(0, 0, 0, 0.13);} */

.card:hover{

  background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 20px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17px);
    border-radius: 10px;
  /* -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(142, 189, 237, 0.68);
      box-shadow: 0px 0px 20px 5px rgba(228, 225, 218, 0.68);
      background: #FFFFFF;
        background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%);
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%); */

}

.Header{

  text-align: center;
  justify-content: center;
  justify-items: center;
  margin: 20px 20px;
  z-index: 2000;
}



#myVideo {
  
  max-width: 50vw;
  
}

.button {

  background-color: #ff9900;
  border-style: solid;
  border-color: #3c4967;
  border-width: 7px;
  width: 100px;
  border-radius: 10px;
}


.button:hover{

  background-color: #3c4967;
  border-style: solid;
  border-color:#ff9900;
  border-width: 7px;
  color: #FFFFFF;
  transform: scale(1.3);
box-shadow: 2px 0px 12px 3px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 2px 0px 12px 3px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 2px 0px 12px 3px rgba(255, 255, 255, 0.75);
  
}

.bar1{
  background-color: #ff9900;
  color: white;
}

.bar2{

  background-color: #3c4967;
  color: white;
}

.bar3{
  background-color: #9fb7e0;
  color: white;
}
.bar4{
  background-color: #141415;
  color: white;
}

.profile{
  background-color: #ffffffff;
}


.Footer{

  position:fixed;
    height: 25px;
    z-index: 1;
    
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    
}


.social-media:hover{
  transform: scale(2)
}



input[type=text],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form input[type=submit] {
  
  background-color: #011002;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=email],
textarea{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form input[type=submit]:hover {
  
  background-color: #9fb7e0;
box-shadow: 1px 0px 23px 3px rgba(0, 0, 0, 0.92);
  -webkit-box-shadow: 1px 0px 23px 3px rgba(0, 0, 0, 0.92);
  -moz-box-shadow: 1px 0px 23px 3px rgba(0, 0, 0, 0.92);
}


.contact_card {
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 1px 1px;
  
  
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

}

.contact_card:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-radius: 20px;
  /* -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(142, 189, 237, 0.68);
  box-shadow: 0px 0px 20px 5px rgba(228, 225, 218, 0.68);
  background: #FFFFFF;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%);
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 25%, #FFE9D0 100%); */

}

.swing-in-top-fwd {
  animation: swing-in-top-fwd 2s cubic-bezier(.175, .885, .32, 1.275) both
}

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1
  }
}


video{

  border-radius: 12px;
}



